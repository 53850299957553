import {USERTOPICS, ADMINTOPICS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    assignedTopics : null,
    adminTopics : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case USERTOPICS : 
            return {
                ...state,
                assignedTopics : actions.payload
               }
        case ADMINTOPICS : 
            return {
                ...state,
                adminTopics : actions.payload
                }
        default:
            return state
    }
}