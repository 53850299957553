import {
  SINGLEUSERVISUALS,
  ALLUSERSVISUAL,
  SINGLEUSERTESTS,
  BAR_COMPARITIVE_CHART,
  SCATTER_GRAPH_DATA,
  SPECIFIC_USER_GRAPH_DATA,
} from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

//List of questions based on a single topic
export const singleuser = (id) => (dispatch) => {
  console.log(id);
  axios
    .get(`${API_URL}/visuals/${id}`)
    .then((res) => {
      dispatch({
        type: SINGLEUSERVISUALS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

//List of all questions
export const allusers = () => (dispatch) => {
  axios
    .get(`${API_URL}/visuals/`)
    .then((res) => {
      dispatch({
        type: ALLUSERSVISUAL,
        payload: res.data,
      });

      //   console.log(res.data)
    })
    .catch((err) => console.log(err));
};

//List of all questions
export const getScatterGraphData = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/visuals/getScatterGraphData`, data)
    .then((res) => {
      dispatch({
        type: SCATTER_GRAPH_DATA,
        payload: res?.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getspecificUserGraphData = (id) => (dispatch) => {
  axios
    .post(`${API_URL}/visuals/getspecificUserGraphData/${id}`)
    .then((res) => {
      dispatch({
        type: SPECIFIC_USER_GRAPH_DATA,
        payload: res?.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

//Single User's test data
export const usertestsdata = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/visuals/comparitive/${id}`)
    .then((res) => {
      dispatch({
        type: SINGLEUSERTESTS,
        payload: res.data,
      });

      //   console.log(res.data)
    })
    .catch((err) => console.log(err));
};

//Single User's test data
export const getBarComparitiveChart = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/visuals/barComparitive/${id}`)
    .then((res) => {
      dispatch({
        type: BAR_COMPARITIVE_CHART,
        payload: res.data,
      });

      //   console.log(res.data)
    })
    .catch((err) => console.log(err));
};
