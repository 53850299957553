import {
  SINGLEUSERVISUALS,
  ALLUSERSVISUAL,
  SINGLEUSERTESTS,
  BAR_COMPARITIVE_CHART,
  SCATTER_GRAPH_DATA,
  SPECIFIC_USER_GRAPH_DATA,
} from "../actions/type";
import isEmpty from "../utils/validate";

const intitalState = {
  singleuservisualdata: null,
  allusersvisualdata: null,
  singleusertestsdata: null,
  barComparitiveChartData: [],
  scatterGraphData: [],
  SpecificUserGraphData: [],
};

export default function (state = intitalState, actions) {
  // console.log(actions)
  switch (actions.type) {
    case SINGLEUSERVISUALS:
      return {
        ...state,
        singleuservisualdata: actions.payload,
      };
    case ALLUSERSVISUAL:
      return {
        ...state,
        allusersvisualdata: actions.payload,
      };
    case SINGLEUSERTESTS:
      return {
        ...state,
        singleusertestsdata: actions.payload[0],
      };
    case BAR_COMPARITIVE_CHART:
      return {
        ...state,
        barComparitiveChartData: actions.payload,
      };
    case SCATTER_GRAPH_DATA:
      return {
        ...state,
        scatterGraphData: actions.payload,
      };
    case SPECIFIC_USER_GRAPH_DATA:
      return {
        ...state,
        SpecificUserGraphData: actions.payload,
      };
    default:
      return state;
  }
}
