import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";

let currentTime;
let decoded;


if (localStorage.capability) {
    const token = JSON.parse(localStorage.getItem("capability"));

    // Decode token and get user info and exp
    decoded = jwt_decode(token.token);

    // check for expired token
    currentTime = Date.now() / 1000;
}

const PrivateRoutes = ({ component: Component, auth, ...rest }) => (
<Route
    {...rest}
    render={(props) =>
    (decoded && decoded.exp < currentTime) || !localStorage.capability ? (
        <Redirect
        to={{
            pathname: "/login",
            state: { from: props.location },
        }}
        />
    ) : (
        <Component {...props} />
    )
    }
/>
);

PrivateRoutes.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoutes);