import { combineReducers } from "redux";
import authreducers from "./authreducers";
import TopicsReducers from "./TopicsReducers";
import resultsReducer from "./resultsReducer";
import resultSheetReducer from "./resultSheetReducer";
import selectTestReducer from "./selectTestReducer";
import testReducer from "./testReducer";
import questionsReducer from "./questionsReducer";
import userListsReducer from "./userListsReducer";
import assignTopicsReducer from "./assignTopicsReducer";
import addRoleReducer from "./addRoleReducer";
import questionTopicReducer from "./questionTopicReducer";
import questionChapterReducer from "./questionChapterReducer";
import questionTypeReducer from "./questionTypeReducer";
import questionComplexityReducer from "./questionComplexityReducer";
import visualsReducer from "./visualsReducer";
import questionPapersReducer from "./questionPapersReducer";
import assignTestReducer from "./assignTestReducer";
import feedbackReducer from "./feedbackReducer";
import iBridgeReducer from "./iBridgeReducer";
import customerReducer from "./customerReducer";
import uploadFileReducer from "./uploadFileReducer";
import userDashboardReducer from "./userDashboardReducer";
import InterviewReducer from "./InterviewReducer";
import capabilityAnalysisApplicationReducer from "./capabilityAnalysisApplicationReducer";

// iProductivity
import domainReducer from "./productivityReducers/domainReducer";
import functionReducer from "./productivityReducers/functionReducer";
import scenarioReducer from "./productivityReducers/scenarioReducer";
import questionReducer from "./productivityReducers/questionReducer";
import productivityTest from "./productivityReducers/testReducer";
import databaseReducer from "./productivityReducers/databaseReducer";
import debuggingReducers from "./productivityReducers/debuggingReducers";
import openAIReducer from "./productivityReducers/openAIReducer";
import codeSubmissionReducer from "./productivityReducers/codeSubmissionReducer";
import runTerminalReducer from "./productivityReducers/runTerminalReducer"
import productivityReportsReducer from "./productivityReducers/productivityReportsReducer";

// export default combineReducers({
//     auth : authreducers,
//     assignedTopics : TopicsReducers,
//     resultsData : resultsReducer,
//     resultSheet : resultSheetReducer,
//     selecttestdata : selectTestReducer,
//     testresult : testReducer,
//     questions : questionsReducer,
//     allUsers : userListsReducer,
//     getusertopics : assignTopicsReducer,
//     userRoles : addRoleReducer,
//     questiontopics : questionTopicReducer,
//     getchapters : questionChapterReducer,
//     questiontypes : questionTypeReducer,
//     questioncomplexity : questionComplexityReducer
// })

const appReducer = combineReducers({
  auth: authreducers,
  assignedTopics: TopicsReducers,
  resultsData: resultsReducer,
  resultSheet: resultSheetReducer,
  selecttestdata: selectTestReducer,
  testresult: testReducer,
  questions: questionsReducer,
  allUsers: userListsReducer,
  getusertopics: assignTopicsReducer,
  userRoles: addRoleReducer,
  questiontopics: questionTopicReducer,
  getchapters: questionChapterReducer,
  questiontypes: questionTypeReducer,
  questioncomplexity: questionComplexityReducer,
  questionpapers: questionPapersReducer,
  visuals: visualsReducer,
  assignedtests: assignTestReducer,
  feedback: feedbackReducer,
  iBridge: iBridgeReducer,
  customer: customerReducer,
  fileData: uploadFileReducer,
  userDashboard: userDashboardReducer,
  InterviewResults: InterviewReducer,
  capabilityAnalysisApplicationReducer: capabilityAnalysisApplicationReducer,

  // iProductivity
  domainReducer: domainReducer,
  functionReducer: functionReducer,
  scenarioReducer: scenarioReducer,
  questionReducer: questionReducer,
  productivityTest: productivityTest,
  productivityDatabases: databaseReducer,
  codeReducer: debuggingReducers,
  openAIReducer: openAIReducer,
  codeSubmissionReducer: codeSubmissionReducer,
  runTerminalReducer: runTerminalReducer,
  productivityReportsReducer: productivityReportsReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USERLOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
