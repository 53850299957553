import {GETQUESTIONCHAPTERS, 
    ADDQUESTIONCHAPTER, 
    EDITQUESTIONCHAPTER,
    GET_ILEARN_COUESES_VIDEOS,
} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getchapters : null,
    addnewchapter : null,
    editchapter : null,
    coursesVideoLink:[],
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETQUESTIONCHAPTERS : 
            return {
                ...state,
                getchapters : actions.payload
               }
        case ADDQUESTIONCHAPTER : 
        return {
            ...state,
            addnewchapter : actions.payload
            }
        case EDITQUESTIONCHAPTER : 
        return {
            ...state,
            editchapter : actions.payload
            }
        
        case GET_ILEARN_COUESES_VIDEOS : 
            return {
                ...state,
                coursesVideoLink : actions.payload
               }
        default:
            return state
    }
}