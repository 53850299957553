import {GETQUESTIONPAPERS, GETFILTEREDQUESTIONS, POSTQUESTIONPAPER, GETQUESTIONPAPERLISTS, TESTASSIGNEDUSERS, ASSIGNTESTUSER, POSTASSIGNTESTS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getquestionpaperlist : null,
    filteredquestions : null,
    postquestionset : null,
    questionpaperdata : null,
    assignedusersdata : null,
    assigntestuserdata : null,
    postassigntest : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETQUESTIONPAPERS : 
            return {
                ...state,
                postquestionset : null,
                assignedusersdata : null,
                assigntestuserdata : null,
                getquestionpaperlist : actions.payload
               }
        case GETFILTEREDQUESTIONS : 
        return {
            ...state,
            postquestionset : null,
            filteredquestions : actions.payload
            }
        case POSTQUESTIONPAPER : 
        return {
            ...state,
            postquestionset : actions.payload
            }
        case GETQUESTIONPAPERLISTS : 
        return {
            ...state,
            questionpaperdata : actions.payload
            }
        case TESTASSIGNEDUSERS : 
        return {
            ...state,
            assignedusersdata : actions.payload
            }
        case ASSIGNTESTUSER : 
        return {
            ...state,
            assigntestuserdata : actions.payload
            }
        case POSTASSIGNTESTS : 
        return {
            ...state,
            postassigntest : actions.payload
            }
            default:
            return state
    }
}