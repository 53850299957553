import { GET_USERDASHBOARD_DATA,GET_INTERVIEW_QUESTION_PAPERS } from "../actions/type";

const initialData={
    userDashboardData:null,
    interviewPapers:null
}

export default function(state=initialData,action){
    switch(action.type){
        case GET_USERDASHBOARD_DATA:
        return{
            ...state,
            userDashboardData:action.payload,
        };
        case GET_INTERVIEW_QUESTION_PAPERS:
            return{
                ...state,
                interviewPapers:action.payload,
            };
        default:
            return state;
    }
}