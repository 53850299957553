import { ADDFEEDBACKTOTABLE } from '../actions/type';

const intitalState = {
    feedbackdata : null
}

export default function (state = intitalState,actions) {
    switch(actions.type) {
        case ADDFEEDBACKTOTABLE : 
            return {
                ...state,
                feedbackdata : actions.payload
               }
        default:
            return state
    }
}