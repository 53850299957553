import { TEST_SELECTED, REPORT_LOADED, BACK_ARROW, EMPTY_REPORT, REPORT_GEN_ERROR } from "../../actions/type"

const initialState = {
    userEmail: "",
    testSelected: "",
    name: "",
    technology: "",
    createdAt: "",
    testId: "",
    testName:"",
    s3Link: "",
    emptyReportMessage: "",
    errorMessage: ""
}

export default function(state=initialState, action)
{
    switch(action.type)
    {
        case TEST_SELECTED:
            return{
                ...state,
                testSelected: true,
                s3Link: false,
                userEmail: action.payload.userEmail,
                testId: action.payload.testId,
                testName: action.payload.testName
            }
        case REPORT_LOADED:
            return {
                ...state,
                s3Link: action.payload.data.reportHtmlLink
            }
        case EMPTY_REPORT:
            return {
                ...state,
                emptyReportMessage: "Insufficient entries by user. Redirecting..."
            }
        case REPORT_GEN_ERROR:
            return {
                ...state,
                errorMessage: "Error retrieving report. Redirecting..."
            }
        case BACK_ARROW:
            return {
                ...state,
                testSelected: false,
                s3Link: "",
                errorMessage: "",
                emptyReportMessage: ""
            }
        default:
            return state
    }
}