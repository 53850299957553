import {GETQUESTIONTYPES, ADDQUESTIONTYPE, EDITQUESTIONTYPE} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getquestiontype : null,
    addnewchapter : null,
    editchapter : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETQUESTIONTYPES : 
            return {
                ...state,
                getquestiontype : actions.payload
               }
        case ADDQUESTIONTYPE : 
        return {
            ...state,
            addnewchapter : actions.payload
            }
        case EDITQUESTIONTYPE : 
        return {
            ...state,
            editchapter : actions.payload
            }
        default:
            return state
    }
}