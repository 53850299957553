import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  useScrollTrigger,
  Slide,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  Men as MenuIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  MoreVert as MoreIcon,
} from "@material-ui/icons";
import person from "../../images/logo/profileb.png";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { logoutUser } from "../../redux/actions/authActions";
import { makeStyles } from "@material-ui/core/styles";
import Logo1 from "../../images/logo/ibi.png"
import Hamburger from "../../images/logo/menu.png";
import clsx from "clsx";
// import { USER_TOKEN } from "./redux/actions/types";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import down from "../../images/logo/expandb.png";
import up from "../../images/logo/collapseb.png";
import logout from "../../images/logo/logout.png";
import close from "../../images/logo/closw.png";
import { usertestsdata} from "../../redux/actions/visualsAction";
import { getincompletetest } from "../../redux/actions/assignTestAction";
import Badge from "@material-ui/core/Badge";

// import CustomizedDialogs from "./reusableComponents/DialogModal";
// import EditLearnerProfile from "./components/Learner_Module/EditLearnerProfile";
// import LearnerResetPassword from "./components/Learner_Module/LearnerResetPassword";
// import SearchComponent from "./reusableComponents/SearchComponent";

// import { Menu as MenuIcon } from "@material-ui/icons";

// --------------------------------------Component-----------------------------------------

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
  menuCollapseContaier: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "none",
    color: "#666666",
    cursor: "pointer",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "&:hover": {
      color: "#003399",
    },
  },
  titleActive: {
    color: "#003399",
  },
  mobileTitle: {
    color: "#666666",
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 600,
  },
  profileIconButton: {
    marginLeft: "18px",
    background: "#F8F9F9",
    border: "2px solid #005a86",
    borderRadius: 25,
    color: "#005a86",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid #006b9f",
      // background: "#005a86",
      // color: "#F8F9F9",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
    marginLeft: "80px",
  },
TopLeftRectangle :{
    top:" -2px",
    left: "-12px",
},
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      // background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // color: '#F8F9F9',
      // '&:hover': {
      //   background: '#1e293a'
      // }
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  
}));

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const Layout = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [comps, setComps] = useState();

  const dispatch = useDispatch();
  const { visuals, assignedtests } = useSelector((state) => state);

  // console.log("assignedtests",assignedtests)


  useEffect(() => {
    dispatch(usertestsdata(user?.email));
    dispatch(getincompletetest(user?.id))
  }, []);

  // console.log("visuals",visuals)
  // const location = useLocation();
  // // console.log("location",location)

  // const{getincompletetestdata}=props?.assignedtests;
  // console.log("assigned",props)
  const { isAuthenticated, user } = props?.auth;
  //  console.log("user layout user",user)

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  const [mobileMenuIcon, setMobileMenuIcon] = useState("closed");
  // const [activeTitle, setActiveTitle] = useState(user?.programsEnrolled?.length > 0 ? "MyPrograms" :"mvk");
  const [activeTitle, setActiveTitle] = useState(
    user?.programsEnrolled?.length > 0 ? "TestTopics" : "TestTopics"
  );

  const { pathname } = props.location;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.history.push("/login");
  };

  const handleMobileMenu = (event) => {
    console.log(event.currentTarget);
    setMobileAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleIlearn = () => {
    window.open(
      `${process.env.REACT_APP_ILEARN_URL}/explore-courses`,
      "_blank"
    );
  };

  //   const handleICapability = () => {
  //     window.open(
  //       `${
  //         process.env.REACT_APP_CAPABILITY_URL
  //       }/menu/ibridge/${USER_TOKEN()}/user`,
  //       "_blank"
  //     );
  //   };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="headerContainer">
          <Toolbar>
            <div  className="headerContent">
              <img
                style={{
                  width: "205px",
                  height: "60px",
                  objectFit: "cover",
                }}
                // width="250"
                // height="60"
                src={Logo1}
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_IBRIDGE_URL}`)
                }
              />
              <div className={classes.grow} >
              {isAuthenticated ? (
        <nav className="navBar" style={{ paddingTop: "2%" }}>
          {/* <h2 className="navBarTitle" style={{color:"#5E72E4"}}>My Learning</h2> */}
          <div
            className="menu-icon"
            onClick={() => {
              mobileMenuIcon === "closed"
                ? setMobileMenuIcon("expand")
                : setMobileMenuIcon("closed");
            }}
          >
            {mobileMenuIcon === "closed" ? (
              <img src={Hamburger} alt="" />
            ) : (
              <img src={close} alt="" />
            )}
          </div>
          <div className="navLinksSearchContainer">
            <ul
              className={
                mobileMenuIcon === "closed" ? "navMenu" : "navMenu active "
              }
            >
              {/* {user?.programsEnrolled?.length > 0 ? (
                <>
                  <li
                    className={
                      activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/myPrograms"
                      onClick={() => {
                        setActiveTitle("MyPrograms");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      My Programs
                    </Link>
                  </li>{" "}
                  <li
                    className={activeTitle === "mvk" ? "menuItems1" : "menuItems"}
                  >
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        setActiveTitle("mvk");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      MVK
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={activeTitle === "mvk" ? "menuItems1" : "menuItems"}
                  >
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        setActiveTitle("mvk");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      MVK
                    </Link>
                  </li>

                  <li
                    className={
                      activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/myPrograms"
                      onClick={() => {
                        setActiveTitle("MyPrograms");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      My Programs
                    </Link>
                  </li>
                </>
              )} */}
              <li
                // className={
                //   activeTitle === "Assigned" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  to="/menu/testsassigned"
                  onClick={() => {
                    setActiveTitle("Assigned");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                  activeTitle === "Assigned" ? "menuItems1" : "menuItems"
                }
                >
                  <span>
                    <Badge
                      badgeContent={assignedtests?.getincompletetestdata?.length}
                      color="secondary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className={classes.TopLeftRectangle}
                    />
                    Assigned Tests
                  </span>
                </Link>
              </li>

              <li
                // className={
                //   activeTitle === "TestTopics" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  to="/menu/userdashboard"
                  onClick={() => {
                    setActiveTitle("TestTopics");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "TestTopics" ? "menuItems1" : "menuItems"
                  }
                >
                  Assessments
                </Link>
              </li>
              <li
                // className={
                //   activeTitle === "Results" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  to="/menu/userResults"
                  onClick={() => {
                    setActiveTitle("Results");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "Results" ? "menuItems1" : "menuItems"
                  }
                >
                  Capability Results
                </Link>
              </li>

              <li
                // className={
                //   activeTitle === "ProductivityResult"
                //     ? "menuItems1"
                //     : "menuItems"
                // }
              >
                <Link
                  to="/menu/prodresult"
                  onClick={() => {
                    setActiveTitle("ProductivityResult");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "ProductivityResult"
                      ? "menuItems1"
                      : "menuItems"
                  }
                >
                  Productivity Result
                </Link>
              </li>

              {/* <li
                className={
                  activeTitle === "DebugTopics" ? "menuItems1" : "menuItems"
                }
              >
                <Link
                  to="/menu/codeTopics"
                  onClick={() => {
                    setActiveTitle("DebugTopics");
                    setMobileMenuIcon("closed");
                  }}
                  className="nav-links"
                >
                  Debug Code
                </Link>
              </li> */}
               <li
                // className={
                //   activeTitle === "DebuggingResults" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  to="/menu/debuggresults"
                  onClick={() => {
                    setActiveTitle("DebuggingResults");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "DebuggingResults" ? "menuItems1" : "menuItems"
                  }
                >
                  Debugging Results
                </Link>
              </li>

              

              <li
                // className={
                //   activeTitle === "UserInfo" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  to="/menu/userinfo"
                  onClick={() => {
                    setActiveTitle("UserInfo");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "UserInfo" ? "menuItems1" : "menuItems"
                  }
                >
                  UserInfo
                </Link>
              </li>

              <li
                // className={
                //   activeTitle === "Visuals" ? "menuItems1" : "menuItems"
                // }
              >
                <Link
                  // to="/menu/visuals"
                  onClick={() => {
                    props.history.push({
                      pathname: `/menu/visuals`,
                      state: {
                        id: user && user?.id,
                        first_name: user && user?.first_name,
                        oldest: visuals?.singleusertestsdata?.oldest,
                        latest: visuals?.singleusertestsdata?.latest,
                      },
                    });
                    setActiveTitle("Visuals");
                    setMobileMenuIcon("closed");
                  }}
                  className={
                    activeTitle === "Visuals" ? "menuItems1" : "menuItems"
                  }
                >
                  Visuals
                </Link>
              </li>
            </ul>
            {/* {props?.location?.pathname === '/myPrograms'  || props?.location?.pathname === "/programs" ? 
               <div className="searchBoxDiv">
                <SearchComponent userId={user._id} /> 
              </div>
              :
              null
            } */}
          </div>
        </nav>
      ) : null}
</div>
              {!isAuthenticated ? (
                <>
                  <div className={classes.menuContainer}>
                    <Typography
                      variant="h5"
                      noWrap
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
                      }
                    >
                      <Link
                        // to="/"
                        className={
                          pathname === "/"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Home
                      </Link>
                    </Typography>
                  </div>
                  {/* <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/about-us"
                        className={
                          pathname === "/about-us"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        About Us
                      </Link>
                    </Typography>
                  </div> */}
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/programs"
                        className={
                          pathname === "/program"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Programs
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/explore"
                        className={
                          pathname === "/explore"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Explore
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap onClick={handleIlearn}>
                      <Link
                        to="/dashboard"
                        className={
                          pathname === "/Course"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Courses
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/sign-in"
                        className={
                          pathname === "/sign-in"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.menuCollapseContaier}>
                    <IconButton onClick={handleMobileMenu}>
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={mobileAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(mobileAnchorEl)}
                      onClose={handleMobileMenuClose}
                    >
                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography
                          variant="h5"
                          onClick={() =>
                            (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
                          }
                          noWrap
                        >
                          <Link
                            to="/"
                            className={
                              pathname === "/"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Home
                          </Link>
                        </Typography>
                      </MenuItem>

                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography variant="h5" noWrap>
                          <Link
                            to="/programs"
                            className={
                              pathname === "/program"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Programs
                          </Link>
                        </Typography>
                      </MenuItem>

                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography variant="h5" noWrap>
                          <Link
                            to="/explore"
                            className={
                              pathname === "/explore"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Explore
                          </Link>
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography variant="h5" noWrap onClick={handleIlearn}>
                          <Link
                            // to="/programs"
                            className={
                              pathname === "/courses"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Courses
                          </Link>
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography variant="h5" noWrap>
                          <Link
                            to="/sign-in"
                            className={
                              pathname === "/sign-in"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Sign In
                          </Link>
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <div className={classes.userDetails}>
                  {/* ------------------------------------------------------------------------ */}

                  <Button
                    disableRipple
                    className={classes.profileIconButton}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    variant="outlined"
                    onClick={handleMenu}
                  >
                    <img src={person} alt="" /> {user?.first_name}{" "}
                    {Boolean(anchorEl) ? (
                      <img src={up} alt="" />
                    ) : (
                      <img src={down} alt="" />
                    )}
                  </Button>

                  {/* ----------------------------------------------------------- */}

                  <Menu
                    className={classes.profileDrop}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {/* <MenuItem
                      // onClick={() => {
                      //   props.logoutUser(props.history);
                      //   handleClose();
                      //   handleLogout();
                      // }}
                      // onClick={() => {
                      //   props.history.push("/editLearnerProfile")
                      //   location.pathname === '/editLearnerProfile' ? setActiveTitle(" ") : null
                      // }}
                      onClick={() => {
                        setOpen(true);
                        setComps("");
                      }}
                    >
                      <EditIcon /> Edit Profile
                    </MenuItem>

                    <MenuItem
                      // onClick={() => {
                      //   props.logoutUser(props.history);
                      //   handleClose();
                      //   handleLogout();
                      // }}
                      onClick={() => {
                        setOpen(true);
                        setComps();
                      }}
                    >
                      <VpnKeyIcon /> Reset Password
                    </MenuItem> */}

                    <MenuItem
                      onClick={() => {
                        props.logoutUser(props.history);
                        handleClose();
                        handleLogout();
                      }}
                    >
                      <img src={logout} alt="" /> Logout
                    </MenuItem>
                  </Menu>
                </div>
              )
              }
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      {isAuthenticated ? (
        // <nav className="navBar" style={{ paddingTop: "2%" }}>
        //   {/* <h2 className="navBarTitle" style={{color:"#5E72E4"}}>My Learning</h2> */}
        //   <div
        //     className="menu-icon"
        //     onClick={() => {
        //       mobileMenuIcon === "closed"
        //         ? setMobileMenuIcon("expand")
        //         : setMobileMenuIcon("closed");
        //     }}
        //   >
        //     {mobileMenuIcon === "closed" ? (
        //       <img src={Hamburger} alt="" />
        //     ) : (
        //       <img src={close} alt="" />
        //     )}
        //   </div>
        //   <div className="navLinksSearchContainer">
        //     <ul
        //       className={
        //         mobileMenuIcon === "closed" ? "navMenu" : "navMenu active "
        //       }
        //     >
        //       {/* {user?.programsEnrolled?.length > 0 ? (
        //         <>
        //           <li
        //             className={
        //               activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
        //             }
        //           >
        //             <Link
        //               to="/myPrograms"
        //               onClick={() => {
        //                 setActiveTitle("MyPrograms");
        //                 setMobileMenuIcon("closed");
        //               }}
        //               className="nav-links"
        //             >
        //               My Programs
        //             </Link>
        //           </li>{" "}
        //           <li
        //             className={activeTitle === "mvk" ? "menuItems1" : "menuItems"}
        //           >
        //             <Link
        //               to="/dashboard"
        //               onClick={() => {
        //                 setActiveTitle("mvk");
        //                 setMobileMenuIcon("closed");
        //               }}
        //               className="nav-links"
        //             >
        //               MVK
        //             </Link>
        //           </li>
        //         </>
        //       ) : (
        //         <>
        //           <li
        //             className={activeTitle === "mvk" ? "menuItems1" : "menuItems"}
        //           >
        //             <Link
        //               to="/dashboard"
        //               onClick={() => {
        //                 setActiveTitle("mvk");
        //                 setMobileMenuIcon("closed");
        //               }}
        //               className="nav-links"
        //             >
        //               MVK
        //             </Link>
        //           </li>

        //           <li
        //             className={
        //               activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
        //             }
        //           >
        //             <Link
        //               to="/myPrograms"
        //               onClick={() => {
        //                 setActiveTitle("MyPrograms");
        //                 setMobileMenuIcon("closed");
        //               }}
        //               className="nav-links"
        //             >
        //               My Programs
        //             </Link>
        //           </li>
        //         </>
        //       )} */}
        //       <li
        //         className={
        //           activeTitle === "Assigned" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/testsassigned"
        //           onClick={() => {
        //             setActiveTitle("Assigned");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           <span>
        //             <Badge
        //               badgeContent={assignedtests?.getincompletetestdata?.length}
        //               color="secondary"
        //               anchorOrigin={{
        //                 vertical: "top",
        //                 horizontal: "left",
        //               }}
        //               className={classes.TopLeftRectangle}
        //             />
        //             Assigned Tests
        //           </span>
        //         </Link>
        //       </li>

        //       <li
        //         className={
        //           activeTitle === "TestTopics" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/userdashboard"
        //           onClick={() => {
        //             setActiveTitle("TestTopics");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Assessments
        //         </Link>
        //       </li>
        //       <li
        //         className={
        //           activeTitle === "Results" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/userResults"
        //           onClick={() => {
        //             setActiveTitle("Results");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Capability Results
        //         </Link>
        //       </li>

        //       <li
        //         className={
        //           activeTitle === "ProductivityResult"
        //             ? "menuItems1"
        //             : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/prodresult"
        //           onClick={() => {
        //             setActiveTitle("ProductivityResult");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Productivity Result
        //         </Link>
        //       </li>

        //       {/* <li
        //         className={
        //           activeTitle === "DebugTopics" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/codeTopics"
        //           onClick={() => {
        //             setActiveTitle("DebugTopics");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Debug Code
        //         </Link>
        //       </li> */}
        //        <li
        //         className={
        //           activeTitle === "DebuggingResults" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/debuggresults"
        //           onClick={() => {
        //             setActiveTitle("DebuggingResults");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Debugging Results
        //         </Link>
        //       </li>

              

        //       <li
        //         className={
        //           activeTitle === "UserInfo" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           to="/menu/userinfo"
        //           onClick={() => {
        //             setActiveTitle("UserInfo");
        //             setMobileMenuIcon("closed");
        //           }}
        //          className="nav-links"
        //         >
        //           UserInfo
        //         </Link>
        //       </li>

        //       <li
        //         className={
        //           activeTitle === "Visuals" ? "menuItems1" : "menuItems"
        //         }
        //       >
        //         <Link
        //           // to="/menu/visuals"
        //           onClick={() => {
        //             props.history.push({
        //               pathname: `/menu/visuals`,
        //               state: {
        //                 id: user && user?.id,
        //                 first_name: user && user?.first_name,
        //                 oldest: visuals?.singleusertestsdata?.oldest,
        //                 latest: visuals?.singleusertestsdata?.latest,
        //               },
        //             });
        //             setActiveTitle("Visuals");
        //             setMobileMenuIcon("closed");
        //           }}
        //           className="nav-links"
        //         >
        //           Visuals
        //         </Link>
        //       </li>
        //     </ul>
        //     {/* {props?.location?.pathname === '/myPrograms'  || props?.location?.pathname === "/programs" ? 
        //        <div className="searchBoxDiv">
        //         <SearchComponent userId={user._id} /> 
        //       </div>
        //       :
        //       null
        //     } */}
        //   </div>
        // </nav>
        null
      ) : null}

      {props.children}
      {/* <Footer /> */}
      {/* {console.log("comps",comps)}
        {console.log("comps.type.displayName",comps.type.displayName)} */}
      {/* <CustomizedDialogs
        title={
          comps.type.displayName === "Connect(EditLearnerProfile)"
            ? "Edit Profile"
            : "Reset Password"
        }
        // children={<EditLearnerProfile />}
        children={comps}
        openPopup={open}
        setOpenPopup={setOpen}
      /> */}
    </>
  );
};

Layout.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
