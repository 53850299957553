import {
  GET_ALL_CUSTOMERS,
  GET_ALL_USER_SUBGROUPS,
  GET_GROUP_USERS,
} from "../actions/type";

const initialState = {
  loading: false,
  customerList: null,
  userSubGroupList: null,
  groupUsers: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CUSTOMERS:
      return {
        ...state,
        customerList: action.payload,
        loading: false,
      };
    case GET_ALL_USER_SUBGROUPS:
      return {
        ...state,
        userSubGroupList: action.payload,
        loading: false,
      };
    case GET_GROUP_USERS:
      return {
        ...state,
        groupUsers: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
