import React, { Component, useState, useEffect } from "react";
import "babel-polyfill";
import Routes from "./routes";
import { connect } from "react-redux";
import { postAccessLog } from "./redux/actions/authActions";

const App = ({ auth, postAccessLog }) => {
  const [timeInterval, setTimeInterval] = useState(0);
  const { user, isAuthenticated } = auth;
  var visibilityChange = document.visibilityState;

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && visibilityChange == "visible") {
      const data = {
        recordedDate: new Date()
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, ""),
      };
      postAccessLog(data);
    }
  }, [timeInterval, isAuthenticated]);

  return <Routes />;
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  postAccessLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
