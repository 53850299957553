import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountCircle,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  ListAlt as ListAltIcon,
  AddBox as AddBoxIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { logoutUser } from "../../redux/actions/authActions";
// import Logo1 from "../../../images/logo/iBridge360-logo.png";
import Logo1 from "../../images/logo/ibi.png";
// import { USER_TOKEN } from "../../../redux/actions/types";
// import { $themeBlue } from "../../../reusableComponents/Colors";
import down from "../../images/logo/expand-icon.png";
import up from "../../images/logo/collapse-icon.png";
import downb from "../../images/logo/expandb.png";
import upb from "../../images/logo/collapseb.png";
import profileb from "../../images/logo/profileb.png";
import { DrawerList } from "./DrawerList";
import logout from "../../images/logo/logout.png";

import { useSelector, useDispatch } from "react-redux";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    minWidth: 35,
  },
  nestedTest: {
    fontSize: "100px",
  },
  nestedIcon: {
    width: "2px",
    margin: 0,
    padding: 0,
  },
  nestedList: {
    marginLeft: "20px",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    color: "#F8F9F9",
    fontWeight: 600,
    letterSpacing: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#003399",
    // backgroundColor:$themeBlue,
    // backgroundColor:"#5E72E4",
    color: "white",
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  profileIconButton: {
    border: "1px solid #003399",
    borderRadius: 25,
    color: "#003399",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid #003399",
      color: "#003399",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  userDetails: {
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
  },
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      // background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // color: '#F8F9F9',
      // '&:hover': {
      //   background: '#1e293a'
      // }
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
}));
function AdminLayout({
  children,
  // auth,
  // logoutUser,
  location,
  history,
}) {
  //   const { pathname } = location;

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  // console.log("auth in adminLayout",auth)

  const [subMenu, setSubMenu] = useState({
    questionOpen: false,
    testOpen: false,
    customerOpen: false,
    analysisOpen: false,
  });

  // const adminCategories = [
  // ];

  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { user } = auth;
  // console.log("user in adminLayout",user)

  const handleSubMenu = (data) => {
    setSubMenu({ ...subMenu, [data]: !subMenu[data] });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  //   const handleiLearnNavigation = (user) => {
  //     window.open(
  //       `${process.env.REACT_APP_ILEARN_URL}/ibridge/${USER_TOKEN()}/${user?.role?.name?.toString()}`,
  //       "_blank"
  //     );
  //   };

  const currentUser = DrawerList.map((list, index) =>
    list.nested ? (
      <>
        {/* {console.log("pathname,rout",location?.pathname,list)} */}
        <ListItem
          button
          key={list.id}
          onClick={() => handleSubMenu(list?.open)}
        >
          <ListItemIcon className={classes.icon}>{list.icon}</ListItemIcon>
          <ListItemText primary={list?.name} />
          {subMenu[`${list?.open}`] ? (
            <img src={up} alt="" />
          ) : (
            <img src={down} alt="" />
          )}
        </ListItem>
        <Collapse in={subMenu[`${list?.open}`]} timeout="auto" unmountOnExit>
          {list?.nested?.map((nest) => (
            <ListItem
              button
              key={nest.id}
              selected={location?.pathname === nest?.route}
              onClick={() => history.push(nest.route)}
              className={classes.nestedList}
            >
              <ListItemIcon className={classes.nestedIcon}>
                {nest.icon}
              </ListItemIcon>
              <ListItemText
                className={classes.nestedTest}
                primary={nest.name}
              />
            </ListItem>
          ))}
        </Collapse>
      </>
    ) : (
      <ListItem
        button
        key={list.id}
        onClick={() => history.push(list.route)}
        selected={location?.pathname === list?.route}
      >
        <ListItemIcon className={classes.icon}>{list.icon}</ListItemIcon>
        <ListItemText primary={list.name} />
      </ListItem>
    )
  );
  //   console.log("user.role.name",user?.role?.name?.toString())
  //   const currentUser = (
  //     (user?.role?.r_id === 1) ? (
  //       adminCategories.map((list, index) => (
  //         <ListItem
  //           button
  //           key={list.id}
  //           selected={pathname === list.route}
  //           onClick={() => history.push(list.route)}
  //         >
  //           <ListItemIcon className={classes.icon}>
  //             {list.icon}
  //           </ListItemIcon>
  //           <ListItemText primary={list.name} />
  //         </ListItem>
  //       ))
  //     )
  //     : (user?.role?.r_id === 4) ? (
  //       serviceProviderCategories.map((list, index) => (
  //         <ListItem
  //           button
  //           key={list.id}
  //           selected={pathname === list.route}
  //           onClick={() => history.push(list.route)}
  //         >
  //           <ListItemIcon className={classes.icon}>
  //             {list.icon}
  //           </ListItemIcon>
  //           <ListItemText primary={list.name} />
  //         </ListItem>
  //       ))
  //     )
  //     :(
  //       mentorCategories.map((list, index) => (
  //         <ListItem
  //           button
  //           key={list.id}
  //           selected={pathname === list.route}
  //           onClick={() => history.push(list.route)}
  //         >
  //           <ListItemIcon className={classes.icon}>
  //             {list.icon}
  //           </ListItemIcon>
  //           <ListItemText primary={list.name} />
  //         </ListItem>
  //       ))
  //     )

  //   )
  const drawer = (
    <div>
      <List>{currentUser}</List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img
            style={{
              width: "205px",
              height: "60px",
              objectFit: "cover",
            }}
            // width="250"
            // height="60"
            src={Logo1}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
            }
            alt=""
          />
          {/* <Typography variant="h6" noWrap className={classes.title}>
            iBridge360 - Admin View
          </Typography> */}
          <div className={classes.grow} />
          <div className={classes.userDetails}>
            <Button
              disableRipple
              className={classes.profileIconButton}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              variant="outlined"
              onClick={handleMenu}
            >
              <img src={profileb} alt="" />
              {/* <AccountCircle className={classes.profileIcon} /> */}
              {user?.first_name ? user?.first_name : user?.role}
              {Boolean(anchorEl) ? (
                <img src={upb} alt="" />
              ) : (
                <img src={downb} alt="" />
              )}
            </Button>
            <Menu
              className={classes.profileDrop}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem
                onClick={() => {
                  handleiLearnNavigation(user);
                  handleClose();
                }}
              >
                iLearn Portal
              </MenuItem> */}

              <MenuItem
                onClick={() => {
                  dispatch(logoutUser(history));
                  handleClose();
                }}
              >
                <img src={logout} alt="" /> Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  );
}

export default withRouter(AdminLayout);
