import {GETQUESTIONTOPICS, ADDQUESTIONTOPIC, EDITQUESTIONTOPIC, GETSTATUSTOPICS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    gettopics : null,
    addnewtopic : null,
    newuser : null,
    statusontopics : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETQUESTIONTOPICS : 
            return {
                ...state,
                gettopics : actions.payload
               }
        case ADDQUESTIONTOPIC : 
        return {
            ...state,
            addnewtopic : actions.payload
            }
        case EDITQUESTIONTOPIC : 
        return {
            ...state,
            newuser : actions.payload
            }
        case GETSTATUSTOPICS : 
        return {
            ...state,
            statusontopics : actions.payload
            }
        default:
            return state
    }
}