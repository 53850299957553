import { GETASSIGNEDTESTS, GETINCOMPLETETESTS } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getassigntest = (id) => dispatch => {
    axios.get(`${API_URL}/assigntests/${id}`)
        .then(res => {
          dispatch({
              type : GETASSIGNEDTESTS, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
}

// export const postassigntest = (params) => dispatch => {
//     // console.log(params)
//       axios.post(`${API_URL}/assigntests/`,params)
//         .then(res => {
//           const result = res.data;
//           const Toast = Swal.mixin({
//             toast: true,
//             position: 'center',
//             showConfirmButton: false,
//             timer: 3000
//           })
//           Toast.fire({
//             icon: 'success',
//             title: 'Added successfully'
//           })
//           this.props.history.push('/menu/questionpapers')
//           dispatch({
//               type : POSTASSIGNTESTS, 
//               payload : res.data
//           })
          
//           // console.log(res.data)
//       })
//       .catch(err => console.log(err))
//   }

export const getassigntestnull = () => dispatch => {
    dispatch({
        type : GETASSIGNEDTESTS, 
        payload : null
    })
}

export const getincompletetest = (id) => dispatch => {
  axios.get(`${API_URL}/assigntests/incomplete/${id}`)
      .then(res => {
        dispatch({
            type : GETINCOMPLETETESTS, 
            payload : res.data
        })
        
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}