import { SET_HINT_DATA, GET_CODE_HINT, CLEAR_HINT_DATA} from "../../actions/type";

const initialState = {
    question: "",
    code: "",
    exception: "",
    generatedHint : "",
    language: ""
}

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case SET_HINT_DATA :
            return{
                ...state,
                question: action.payload.question,
                code: action.payload.code,
                exception: action.payload.exception,
                language: action.payload.language,
                generatedHint: ""
            }
        case GET_CODE_HINT:
            return{
                ...state, 
                generatedHint: action.payload
            }
        case CLEAR_HINT_DATA:
            return {
                ...state,
                question: "",
                code: "",
                exception: "",
                language: "",
                generatedHint: ""
            }
        default:
            return state
    }
}