import {
  IBRIDGETEST,
  IBRIDGEUSER,
  GET_IBRIDGE_ICAP_TEST,
} from "../actions/type";

const intitalState = {
  redirectData: null,
  user: null,
  ibridgeUserData: null,
  ibridgeTestTopics: null,
};

export default function (state = intitalState, actions) {
  switch (actions.type) {
    case IBRIDGETEST:
      return {
        ...state,
        redirectData: actions.payload,
      };
    case IBRIDGEUSER:
      return {
        ...state,
        ibridgeUserData: actions.payload,
      };
    case GET_IBRIDGE_ICAP_TEST:
      return {
        ...state,
        ibridgeTestTopics: actions.payload,
      };
    default:
      return state;
  }
}
