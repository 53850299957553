import { GET_PRODUCTIVITY_DATABASES,GET_PRODUCTIVITY_DATABASES_INFO  } from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  databaseList: [],
  databaseInfo:{},
};

export default function (state = intitalState, actions) {
  switch (actions.type) {
    case GET_PRODUCTIVITY_DATABASES:
      return {
        ...state,
        databaseList: actions.payload,
      };
      case GET_PRODUCTIVITY_DATABASES_INFO:
      return {
        ...state,
        databaseInfo: actions.payload,
      };
    default:
      return state;
  }
}
