import {SQL_CODE_SUBMISSION, GET_CORRECT_SQL_QUERY, GET_BOILER_PLATE_CODE, PYTHON_CODE_SUBMISSION, PYTHON_CODE_INI, SQL_TEST_CASE_EVALUATION_ENABLED, CLEAR_CODE_SUBMISSION_REDUCER } from "../../actions/type"

const initialState = {
    test: "",
    question: "",
    database: "",  
    boilerPlateCode: "", 
    code:"",
    totalCases: "",
    noOfCasesCleared : "",
    allClear: "",
    errorMessage: "",
    capabilityUserId: "",
    language: "",
    compileInput: "",
    compileOutput: "",
    compileEvaluateResult: "",
    correctQuery: "",
    sqlTestCaseEvaluate: false
}

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case SQL_TEST_CASE_EVALUATION_ENABLED:
            console.log('SQL_TEST_CASE_EVALUATION_ENABLED: ', action.payload)
            return{
                ...state,
                sqlTestCaseEvaluate: action.payload
            }
        case GET_BOILER_PLATE_CODE:
            return {
                ...state,
                boilerPlateCode: action.payload
            }
        case GET_CORRECT_SQL_QUERY:
            return {
                ...state,
                correctQuery: action.payload
            }
        case SQL_CODE_SUBMISSION:
            console.log('SQL_CODE_SUBMISSION REDUC: ', action.payload);
            if(action.payload.allClear){
                return{
                    ...state,
                    allClear: action.payload.allClear,
                    codeSubmissionOutput: action.payload.codeSubmissionOutput,
                    errorMessage: ""
                }
            }
            else{
                if(!action.payload.allClear && action.payload.errorMessage)
                {
                    return{
                        ...state,
                        allClear: false,
                        errorMessage: action.payload.errorMessage
                    }
                }
                else if(!action.payload.allClear)
                {   
                    return{
                        ...state,
                        allClear: false,
                        codeSubmissionOutput: action.payload.codeSubmissionOutput,
                        errorMessage: ""
                    }
                }
            }
        case PYTHON_CODE_SUBMISSION:
            if(action.payload.allClear){
                return{
                    ...state,
                    allClear: action.payload.allClear,
                    noOfCasesCleared: action.payload.score,
                    totalCases: action.payload.totalTestCases
                }
            }
            else{
                if(action.payload.errorMessage)
                {
                    return{
                        ...state,
                        noOfCasesCleared: action.payload.score,
                        totalCases: action.payload.totalTestCases,
                        errorMessage: action.payload.errorMessage,
                        allClear: false
                    }
                }
                else
                {
                    return{
                        ...state,
                        allClear: false,
                        errorMessage: ""
                    }
                }
            } 
        case PYTHON_CODE_INI:
            if(action.payload.allClear){
                return{
                    ...state,
                    allClear: action.payload.allClear,
                    compileInput: action.payload.input,
                    compileOutput: action.payload.output,
                    compileEvaluateResult: action.payload.result
                }
            }
            else{
                if(action.payload.compilerError)
                {
                    return{
                        ...state,
                        compileInput: action.payload.input,
                        compileOutput: action.payload.output,
                        compileEvaluateResult: action.payload.result,
                        allClear: false
                    }
                }
                else
                {
                    return{
                        ...state,
                        allClear: false,
                        errorMessage: ""
                    }
                }
            }
        case CLEAR_CODE_SUBMISSION_REDUCER:
            return initialState        
        default:
            return state
    }
}