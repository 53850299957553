import { FETCH_CLUSTER, FETCH_OVERALL, FETCH_TOPICWISE, SET_CAPABILITY_ANALYSIS_ERROR, LOADING_CAPABILITY_ANALYSIS_DATA } from "../actions/type";

const initialState = {
    overallData: "",
    clusterData: "",
    topicWiseData: "",
    loading: false,
    errorMessage: ""
}

export default function (state=initialState, action)
{
    switch(action.type)
    {
        case LOADING_CAPABILITY_ANALYSIS_DATA:
            return{
                ...state,
                loading: true,
            }
        case FETCH_OVERALL:
            return {
                ...state,
                errorMessage: "",
                overallData: action.payload,
                loading: false
            }
        case FETCH_CLUSTER:
            return {
                ...state,
                errorMessage: "",
                clusterData: action.payload,
                loading: false
            }
        case FETCH_TOPICWISE:
            return {
                ...state,
                errorMessage: "",
                topicWiseData: action.payload,
                loading: false
            }
        case SET_CAPABILITY_ANALYSIS_ERROR:
            return {
                ...state,
                overallData: "",
                clusterData: "",
                topicWiseData: "",
                errorMessage: action.payload.error,
                loading: false
            }
        default:
            return state
    }
}