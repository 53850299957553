import {
  GET_ALL_DEBUGGING_CODES,
  GET_DEBUGGING_CODES_BY_TECHNOLOGY,
  GET_DEBUGGING_SESSION,
  GET_DEBUGGING_CODES_FOR_FEEDBACK,
  GET_ALL_USER_SESSIONS,
  GET_INDUVIDUAL_USER_SESSIONS
} from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  debuggingCodeList: [],
  technologyCodeList: [],
  debuggingSessionData: {},
  debuggingQuestionCodes: [],
  debuggingSessions: [],
  induvidualDebuggingSessionData:[],
  // newuser : null
};

export default function (state = intitalState, actions) {
  // console.log(actions)
  switch (actions.type) {
    case GET_ALL_DEBUGGING_CODES:
      return {
        ...state,
        debuggingCodeList: actions.payload,
      };
    case GET_DEBUGGING_CODES_BY_TECHNOLOGY:
      return {
        ...state,
        technologyCodeList: actions.payload,
      };
    case GET_DEBUGGING_SESSION:
      return {
        ...state,
        debuggingSessionData: actions.payload,
      };
    case GET_DEBUGGING_CODES_FOR_FEEDBACK:
      return {
        ...state,
        debuggingQuestionCodes: actions.payload,
      };
    case GET_ALL_USER_SESSIONS:
      return {
        ...state,
        debuggingSessions: actions.payload,
      };
      case GET_INDUVIDUAL_USER_SESSIONS:
        return {
         ...state,
          induvidualDebuggingSessionData: actions.payload,
         
        };
    default:
      return state;
  } 
}
