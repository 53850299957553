import {
  GET_TECHNOLOGY_LIST,
  GET_DOMAIN_LIST,
  GET_TECHNOLOGY_INFO,
  GET_TRUETECHNOLOGY_LIST,
  GET_DOMAIN_INFO,
  GET_ENABLED_DOMAIN_LIST,
} from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  getTechnologyList: [],
  getDomainList: [],
  technologyInfo: {},
  domainInfo: {},
  enabledDomains: [],
};

export default function (state = intitalState, actions) {
  switch (actions.type) {
    case GET_TECHNOLOGY_LIST:
      return {
        ...state,
        getTechnologyList: actions.payload,
      };
    case GET_TECHNOLOGY_INFO:
      return {
        ...state,
        technologyInfo: actions.payload,
      };
    case GET_DOMAIN_LIST:
      return {
        ...state,
        getDomainList: actions.payload,
      };
    case GET_ENABLED_DOMAIN_LIST:
      return {
        ...state,
        enabledDomains: actions.payload,
      };
    case GET_TRUETECHNOLOGY_LIST:
      return {
        ...state,
        getTrueTechnologyList: actions.payload,
      };
    case GET_DOMAIN_INFO:
      return {
        ...state,
        domainInfo: actions.payload,
      };
    default:
      return state;
  }
}
