import {GETQUESTIONCOMPLEXITY, ADDQUESTIONCOMPLEXITY, EDITQUESTIONCOMPLEXITY, GETSTATUSCOMPLEXITY} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getquestioncomplexity : null,
    addcomplexity : null,
    editcomplexity : null,
    statusonskills : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETQUESTIONCOMPLEXITY : 
            return {
                ...state,
                getquestioncomplexity : actions.payload
               }
        case ADDQUESTIONCOMPLEXITY : 
        return {
            ...state,
            addcomplexity : actions.payload
            }
        case EDITQUESTIONCOMPLEXITY : 
        return {
            ...state,
            editcomplexity : actions.payload
            }
        case GETSTATUSCOMPLEXITY : 
        return {
            ...state,
            statusonskills : actions.payload
            }
        default:
            return state
    }
}