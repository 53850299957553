import {
  GET_FUNCTION_LIST,
  GET_FUNCTION_INFO,
  GET_ENABLED_FUNCTION_LIST,
} from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  getFunctionList: null,
  getFunctionInfo: {},
  enabledFunctions: [],
  // edituserrole : null,
  // newuser : null
};

export default function (state = intitalState, actions) {
  // console.log(actions)
  switch (actions.type) {
    case GET_FUNCTION_LIST:
      return {
        ...state,
        getFunctionList: actions.payload,
      };
    case GET_ENABLED_FUNCTION_LIST:
      return {
        ...state,
        enabledFunctions: actions.payload,
      };
    case GET_FUNCTION_INFO:
      return {
        ...state,
        getFunctionInfo: actions.payload,
      };
    //  case GET_DOMAIN_LIST :
    //  return {
    //      ...state,
    //      getDomainList : actions.payload
    //    }
    // case ADDNEWUSER :
    // return {
    //     ...state,
    //     newuser : actions.payload
    //     }
    default:
      return state;
  }
}
