import { ALLUSERS, COMPARITIVEUSERLISTS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    allUsers : null,
    comparitivechartuserdata : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case ALLUSERS : 
            return {
                ...state,
                allUsers : actions.payload
               }
        case COMPARITIVEUSERLISTS : 
            return {
                ...state,
                comparitivechartuserdata : actions.payload
                }
        default:
            return state
    }
}
