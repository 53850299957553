import {POSTTEST, SETPOSTDATANULL, DELETETEST} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    testresult : null,
    deletetestdata : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case POSTTEST : 
            return {
                ...state,
                testresult : actions.payload
               }
        case DELETETEST : 
        return {
            ...state,
            deletetestdata : actions.payload
            }
        case SETPOSTDATANULL : 
        return {
            ...state,
            testresult : null
            }
        default:
            return state
    }
}