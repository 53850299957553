import {USERRESULTS, ALLRESULTS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    resultsData : null,
    allResultsdata : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case USERRESULTS : 
            return {
                ...state,
                resultsData : actions.payload
               }
        case ALLRESULTS : 
            return {
                ...state,
                allResultsdata : actions.payload
                }
        default:
            return state
    }
}