import { SETCURRENTUSER, USERLOGOUT } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";
import setAuthToken from "../utils/setAuthToken";

export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${API_URL}/login`, userData)
    .then((response) => {
      // console.log("red",response.data)
      const data = response.data;
      const local = {
        token: data.token,
        username: data.username,
      };

      localStorage.setItem("capability", JSON.stringify(local));
      setAuthToken(local.token);
      dispatch(currentUser(local));
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: "Signed in successfully",
      });
    })
    .catch((err) => {
      console.log(err);
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        Swal.fire({ icon: "error", text: `${err.response.data.message}` });
      } else {
        Swal.fire({
          icon: "error",
          text: `Something went wrong in the Server`,
        });
      }
    });
};

export const currentUser = (token) => (dispatch) => {
  axios
    .get(`${API_URL}/userlists/${token.username}`)
    .then((res) => {
      if (res.data.length > 0) {
        dispatch({
          type: SETCURRENTUSER,
          payload: res.data[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SETCURRENTUSER,
        payload: null,
      });
      Swal.fire({ icon: "error", text: `Something went wrong in the Server` });
    });
};

// Send password reset link
export const sendPasswordResetLink = (data, history) => (dispatch) => {
  axios
    .put("/api/userlists/forgotPassword", data)
    .then((res) => {
      const { message } = res.data;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: message,
      });
      history.push("/login");
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

// Reset password
export const resetPassword = (data, history) => (dispatch) => {
  axios
    .put("/api/userlists/resetPassword", data)
    .then((res) => {
      const { message } = res.data;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: message,
      });
      history.push("/login");
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Post access log
export const postAccessLog = (data) => async () => {
  await axios.post("/api/userlists/postAccessLog", data);
};

export const logoutUser = () => (dispatch) => {
  localStorage.clear();
  dispatch({
    type: USERLOGOUT,
    payload: { logout: true },
  });
};
