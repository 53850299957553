import {
  GET_SCENARIO_LIST,
  GET_SCENARIO_INFO,
  GET_ENABLED_SCENARIO_LIST,
} from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  getScenarioList: null,
  getScenarioInfo: {},
  enabledScenarios: [],
  // edituserrole : null,
  // newuser : null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = intitalState, actions) {
  // console.log(actions)
  switch (actions.type) {
    case GET_SCENARIO_LIST:
      return {
        ...state,
        getScenarioList: actions.payload,
        // edituserrole : null
      };
    case GET_ENABLED_SCENARIO_LIST:
      return {
        ...state,
        enabledScenarios: actions.payload,
        // edituserrole : null
      };
    case GET_SCENARIO_INFO:
      return {
        ...state,
        getScenarioInfo: actions.payload,
      };
    //  case GET_DOMAIN_LIST :
    //  return {
    //      ...state,
    //      getDomainList : actions.payload
    //    }
    // case ADDNEWUSER :
    // return {
    //     ...state,
    //     newuser : actions.payload
    //     }
    default:
      return state;
  }
}
