import {GETUSERROLES, EDITUSERROLE, ADDNEWUSER} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getuserrole : null,
    edituserrole : null,
    newuser : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETUSERROLES : 
            return {
                ...state,
                getuserrole : actions.payload,
                edituserrole : null
               }
        case EDITUSERROLE : 
        return {
            ...state,
            edituserrole : actions.payload
            }
        case ADDNEWUSER : 
        return {
            ...state,
            newuser : actions.payload
            }
        default:
            return state
    }
}