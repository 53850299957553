import {
  GET_TEST_ID,
  GET_TEST_QUESTIONS,
  EXECUTE_SQL,
  GET_SQL_SCHEMA,
  GET_ALL_PRODUCTIVITY_TESTS,
  GET_TEST_DATA,
  SET_PRODUCTIVITY_LOADING,
  GET_ALL_USER_TESTS,
  CLEAR_TERMINAL
} from "../../actions/type";
import isEmpty from "../../utils/validate";

const intitalState = {
  loading: false,
  testId: null,
  testQuestions: [],
  sqlData: null,
  sqlException: null,
  sqlSchema: null,
  testList: [],
  testData: null,
  userTestList:[],
  clearTerminal: false
};

export default function (state = intitalState, actions) {
  switch (actions.type) {
    case SET_PRODUCTIVITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_TEST_ID:
      return {
        ...state,
        testId: actions.payload,
      };
    case GET_TEST_QUESTIONS:
      return {
        ...state,
        testQuestions: actions.payload,
      };
    case GET_SQL_SCHEMA:
      return {
        ...state,
        sqlSchema: actions.payload,
      };
    case GET_TEST_DATA:
      return {
        ...state,
        testData: actions.payload,
      };
    case GET_ALL_PRODUCTIVITY_TESTS:
      return {
        ...state,
        loading: false,
        testList: actions.payload,
      };
      case   GET_ALL_USER_TESTS:
      return {
        ...state,
        loading: false,
        userTestList: actions.payload,
      };
    case EXECUTE_SQL:
      if (actions.payload?.type === "success") {
        return {
          ...state,
          sqlData: actions?.payload?.data,
        };
      } else {
        return {
          ...state,
          sqlException: actions?.payload?.data,
        };
      }
    case CLEAR_TERMINAL:
      return {
        ...state,
        clearTerminal: actions?.payload?.data,
      }
    default:
      return state;
  }
}
