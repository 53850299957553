import React, { lazy, Suspense } from "react";
import PrivateRoute from "./components/reusableComponents/PrivateRoutes";
import Layout from "./components/layout/Layout";
import AdminLayout from "./components/layout/adminLayout";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Fallback from "./Fallback";

// Lazy load components
const QuestionsPage = lazy(() =>
  import("./components/questionspage/questionsPage")
);
const Login = lazy(() => import("./components/login/login"));
const UserResults = lazy(() =>
  import("./components/users/userresults/userresults")
);
const LandingPage = lazy(() => import("./components/landing/landing"));
const PDF = lazy(() => import("./components/viewresults/generatepdf"));
const SelectTest = lazy(() => import("./components/selectTest/selecttest"));
const Test = lazy(() => import("./components/test/test"));
const Subscribe = lazy(() => import("./components/subscribe/subscribe"));
const Success = lazy(() => import("./components/subscribe/success"));
const UserDashboard = lazy(() =>
  import("./components/users/userdashboard/userdashboard")
);
const AssignTests = lazy(() => import("./components/assigntests/assigntests"));
const AssignTestUsers = lazy(() => import("./components/assigntests/users"));
const Visuals = lazy(() => import("./components/visuals/visuals"));
const TestsAssigned = lazy(() =>
  import("./components/users/assignedtests/testsassigned")
);
const Rules = lazy(() => import("./components/users/assignedtests/rules"));
const IBridgeLanding = lazy(() => import("./components/ibridge/landing"));
const IBridgeTest = lazy(() => import("./components/ibridge/ibridgeTest"));
const ForgotPassword = lazy(() =>
  import("./components/forgotPassword/forgotPassword")
);
const ResetPassword = lazy(() =>
  import("./components/forgotPassword/resetPassword")
);
const ResultIbridge = lazy(() => import("./components/ibridge/ResultIbridge"));
const IbridgeUserDashboard = lazy(() =>
  import("./components/ibridge/ibridgeUserDashboard")
);
const UserInfo = lazy(() => import("./components/UserInfo/UserInfo"));
const InterviewDashboard = lazy(() =>
  import("./components/dashboard/interviewDashboard")
);
const Query = lazy(() => import("./components/executeQurey/Query/Query"));
const Python = lazy(() => import("./components/executeQurey/Python/Python"));
const ProductivityTest = lazy(() =>
  import("./components/test/ProductivityTest")
);
const PythonCoding = lazy(() => import("./components/test/PythonCoding"));
const AdminRoutes = lazy(() => import("./AdminRoutes"));
const JavaCoding = lazy(() => import("./components/test/JavaCoding"));
const ViewResults = lazy(() => import("./components/viewresults/viewresults"));
const JavaScriptCoding = lazy(() =>
  import("./components/test/JavaScriptCoding")
);
const CCoding = lazy(() => import("./components/test/CCoding"));
const CppCoding = lazy(() => import("./components/test/CppCoding"));
const HtmlEditorScreen = lazy(() =>
  import(
    "./components/reusableComponents/HtmlEditorIntegration/HtmlEditorScreen"
  )
);
const Linux = lazy(() => import("./components/test/LinuxCoding"));
const Csharp = lazy(() => import("./components/test/C#coding"));
const ProdResult = lazy(() =>
  import("./components/users/userresults/prodresult")
);
const ViewProductivityTest = lazy(() =>
  import("./components/results/ProductivityResults/ViewProductivityTest")
);
const DebuggingCodeTopics = lazy(() =>
  import("./components/domainArena/DeuggingCodes/DebuggingCodeTopics")
);
const DebuggingCode = lazy(() =>
  import("./components/domainArena/DeuggingCodes/DebuggingCode")
);
const DebugFeedback = lazy(() =>
  import("./components/domainArena/DeuggingCodes/DebugFeedback")
);
const LearningAssistantAI = lazy(() =>
  import("./components/ai_learrning_assistant/ai_learning_assistant")
);
const InterviewSimulator = lazy(() =>
  import("./components/interview_simulator/interview_simulator")
);

const DebuggingResult = lazy(() =>
  import("./components/users/userresults/debuggingresults")
);

const ViewDebuggingSession = lazy(() =>
  import("./components/domainArena/DeuggingCodes/ViewDebuggingSession")
);
// const SelectTests=lazy(() =>
//   import("./components/selectTest/selectTests")
// );

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Switch>
          {/* admin Routes */}
          <PrivateRoute path="/admin" component={AdminRoutes} />

          {/* user Routes */}
          <Route path="/" component={LandingPage} exact />
          <PrivateRoute
            path="/menu/pythonCoding/:testId"
            component={PythonCoding}
          />
          <PrivateRoute
            path="/menu/javaCoding/:testId"
            component={JavaCoding}
          />
          <PrivateRoute path="/menu/CCoding/:testId" component={CCoding} />
          <PrivateRoute path="/menu/CppCoding/:testId" component={CppCoding} />
          <PrivateRoute path="/menu/LinuxCoding/:testId" component={Linux} />
          <PrivateRoute path="/menu/CsharpCoding/:testId" component={Csharp} />
          <PrivateRoute
            path="/menu/javascriptCoding/:testId"
            component={JavaScriptCoding}
          />
          <PrivateRoute
            path="/menu/htmlEditor/:testId"
            component={HtmlEditorScreen}
          />
          <PrivateRoute
            path="/menu/sqlCoding/:testId"
            component={ProductivityTest}
          />
          <PrivateRoute path="/menu/test" component={Test} />
          <Route path="/login" component={Login} />
          <Route exact path="/ibridge/test" component={IBridgeTest} />
          <PrivateRoute
            path="/menu/interviewDashboard"
            component={InterviewDashboard}
          />
          <PrivateRoute
            path="/menu/debugFeedback/:sessionId"
            component={DebugFeedback}
          />
          <PrivateRoute
            path="/learningassistantai"
            component={LearningAssistantAI}
          />
          <PrivateRoute
            path="/interviewSimulator"
            component={InterviewSimulator}
          />
          <Layout>
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/resetPassword/:token" component={ResetPassword} />
            <Route
              exact
              path="/menu/ibridge/:skill/:user/:topicId/:topicName/:acronym/:programId"
              component={IBridgeLanding}
            />
            <Route
              exact
              path="/menu/ibridgeResults/:user"
              component={ResultIbridge}
            />
            <Route
              exact
              path="/menu/ibridgeUserDashboard/:user"
              component={IbridgeUserDashboard}
            />
            <PrivateRoute path="/menu/UserResults" component={UserResults} />
            <PrivateRoute path="/menu/selecttest" component={SelectTest} />
            <PrivateRoute path="/menu/query" component={Query} />
            <PrivateRoute path="/menu/python" component={Python} />
            <PrivateRoute
              path="/menu/assigntestusers"
              component={AssignTestUsers}
            />
            <PrivateRoute path="/menu/assigntests" component={AssignTests} />
            <PrivateRoute
              path="/menu/userdashboard"
              component={UserDashboard}
            />
            <PrivateRoute path="/menu/visuals" component={Visuals} />
            <PrivateRoute path="/menu/subscribe" component={Subscribe} />
            <PrivateRoute
              path="/menu/questionsPage"
              component={QuestionsPage}
            />
            <PrivateRoute path="/menu/success" component={Success} />
            <PrivateRoute path="/menu/pdf" component={PDF} />
            <PrivateRoute
              path="/menu/testsassigned"
              component={TestsAssigned}
            />
            <PrivateRoute path="/menu/rules" component={Rules} />
            <PrivateRoute path="/menu/userinfo" component={UserInfo} />
            <PrivateRoute path="/menu/viewresults" component={ViewResults} />
            <PrivateRoute
              path="/menu/codeTopics"
              component={DebuggingCodeTopics}
            />
            <PrivateRoute
              path={`/menu/viewUserQueryresult`}
              component={ViewProductivityTest}
            />
            <PrivateRoute
              path={`/menu/view-user-debugging-session/:sessionId`}
              component={ViewDebuggingSession}
            />
            <PrivateRoute path="/menu/prodresult" component={ProdResult} />
            <PrivateRoute
              path="/menu/debuggresults"
              component={DebuggingResult}
            />
          </Layout>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
