import { ESTABLISH_SOCKET_CONNECTION, DISCONNECT_SOCKET } from "../../actions/type";

const initialState = {
    socketConnection: null
}

export default function(state = initialState, action){
    switch(action.type)
    {
    case ESTABLISH_SOCKET_CONNECTION:
        console.log(action)
        return {
            ...state,
            socketConnection: action.payload
        }
    case DISCONNECT_SOCKET:
        return{
            ...state,
            socketConnection: null
        }
    default:
        return state
    }
}