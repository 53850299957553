import {GETASSIGNEDTESTS, GETINCOMPLETETESTS} from '../actions/type';

const intitalState = {
    getassignedtestsdata : null,
    postassigntest : null,
    getincompletetestdata: null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETASSIGNEDTESTS : 
            return {
                ...state,
                getassignedtestsdata : actions.payload,
                postassigntest : null
               }
        // case POSTASSIGNTESTS : 
        // return {
        //     ...state,
        //     getassignedtestsdata : null,
        //     postassigntest : actions.payload
        //     }
        case GETINCOMPLETETESTS : 
        return {
            ...state,
            getassignedtestsdata : null,
            getincompletetestdata : actions.payload,
            postassigntest : null
            }
        default:
            return state
    }
}