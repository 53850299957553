import {
  UPLOAD_IMAGE,
  UPLOAD_FILE_PROCESS,
  UPLOAD_AUDIO,
  UPLOAD_VIDEO,
  UPLOAD_FILE,
} from "../actions/type";

const initialState = {
  loading: false,
  uploadFileProcess: false,
  imageData: null,
  audioData: null,
  videoData: null,
  userFileData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_PROCESS:
      return {
        ...state,
        uploadFileProcess: true,
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        imageData: action.payload,
        uploadFileProcess: false,
        loading: false,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        userFileData: action.payload,
        uploadFileProcess: false,
        loading: false,
      };
    case UPLOAD_AUDIO:
      return {
        ...state,
        audioData: action.payload,
        uploadFileProcess: false,
        loading: false,
      };
    case UPLOAD_VIDEO:
      return {
        ...state,
        videoData: action.payload,
        uploadFileProcess: false,
        loading: false,
      };
    default:
      return state;
  }
}
