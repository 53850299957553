import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store'
import { Provider } from 'react-redux';
import { currentUser, logoutUser } from './redux/actions/authActions';
import setAuthToken from './redux/utils/setAuthToken';
import jwt_decode from "jwt-decode";

import "./index.scss"

if(localStorage.capability) {
    const user = JSON.parse(localStorage.getItem('capability'));
    // Set auth token header auth
    setAuthToken(user.token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(user.token);
    // Set user and is Authenticated
    store.dispatch(currentUser(user));
  
    // check for expired token
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        // Logout User
        store.dispatch(logoutUser());
        // TODO: Clear current Profile
  
        // Redirect to login
        window.location.href = '/'
    }
}


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
