import {GETASSIGNTOPICS, POSTASSIGNTOPICS, EDITASSIGNTOPICS, DELETEASSIGNTOPICS} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    getusertopics : null,
    postusertopics : null,
    editusertopics : null,
    deleteusertopics : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GETASSIGNTOPICS : 
            return {
                ...state,
                getusertopics : actions.payload
               }
        case POSTASSIGNTOPICS : 
        return {
            ...state,
            getusertopics : null,
            postusertopics : null,
            postusertopics : actions.payload
            }
        case EDITASSIGNTOPICS : 
        return {
            ...state,
            getusertopics : null,
            postusertopics : null,
            editusertopics : actions.payload
            }
        case DELETEASSIGNTOPICS : 
        return {
            ...state,
            getusertopics : null,
            postusertopics : null,
            deleteusertopics : actions.payload
            }
        default:
            return state
    }
}