/* eslint-disable import/no-anonymous-default-export */
import { GET_QUESTION_LIST, GET_QUESTION_INFO } from '../../actions/type';
import isEmpty from '../../utils/validate'

const intitalState = {
    getQuestionList: null,
    getQuestionInfo: {}
    // edituserrole : null,
    // newuser : null
}

export default function (state = intitalState, actions) {
    // console.log(actions)
    switch (actions.type) {
        case GET_QUESTION_LIST:
            return {
                ...state,
                getQuestionList: actions.payload,
                // edituserrole : null
            }
        case GET_QUESTION_INFO:
            return {
                ...state,
                getQuestionInfo: actions.payload,
                // edituserrole : null
            }


        //  case GET_DOMAIN_LIST : 
        //  return {
        //      ...state,
        //      getDomainList : actions.payload
        //    }
        // case ADDNEWUSER : 
        // return {
        //     ...state,
        //     newuser : actions.payload
        //     }
        default:
            return state
    }
}