import {SINGLETOPICQUESTIONS, ALLTOPICQUESTIONS, GETQUESTIONSRELATEDDATA, ADDNEWQUESTION, GETEDITQUESTION, UPDATEQUESTION} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    singletopicquestionsdata : null,
    alltopicquestionsdata : null,
    questionrelateddata : null,
    addquestion : null,
    editquestiondata : null,
    updatequestiondata : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case SINGLETOPICQUESTIONS : 
            return {
                ...state,
                singletopicquestionsdata : actions.payload,
                alltopicquestionsdata : null,
                addquestion : null
               }
        case ALLTOPICQUESTIONS : 
            return {
                ...state,
                singletopicquestionsdata : null,
                addquestion : null,
                alltopicquestionsdata : actions.payload
                }
        case GETQUESTIONSRELATEDDATA : 
        return {
            ...state,
            questionrelateddata : actions.payload,
            addquestion : null
            }
        case ADDNEWQUESTION : 
        return {
            ...state,
            addquestion : actions.payload
            }
        case GETEDITQUESTION : 
        return {
            ...state,
            updatequestiondata : null,
            editquestiondata : actions.payload,
            addquestion : null
            }
        case UPDATEQUESTION : 
        return {
            ...state,
            updatequestiondata : actions.payload,
            addquestion : null
            }
        default:
            return state
    }
}