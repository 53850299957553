import {
    GET_INTERVIEW_RESULTS_LIST, 
    GET_INTERVIEW_RESULT_SHEET,
} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    interviewResultSList : null,
    interviewResultSheet:null,
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case GET_INTERVIEW_RESULTS_LIST : 
            return {
                ...state,
                interviewResultSList : actions.payload
            }
        case GET_INTERVIEW_RESULT_SHEET : 
            return {
                ...state,
                interviewResultSheet : actions.payload
        }
        default:
            return state
    }
}