import React from "react";
import { LinearProgress, Box } from "@mui/material";
import logo from "./images/logo/ibridgelogo.png"; // Replace with your logo path

const Fallback = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      textAlign: "center",
    }}
  >
    <img src={logo} alt="Logo" style={{ width: 100, marginBottom: 16 }} />
    <LinearProgress sx={{ width: "100%", maxWidth: 400 }} />
    <p>Loading...</p>
  </Box>
);

export default Fallback;
