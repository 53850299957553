import {SELECTTEST, TESTQUESTIONS, PREDEFINEDTESTQUESTIONS, QUESTIONSNULL} from '../actions/type';
import isEmpty from '../utils/validate'

const intitalState = {
    selecttestdata : null,
    testquestions : null,
    predefinedquestions : null
}

export default function (state = intitalState,actions) {
    // console.log(actions)
    switch(actions.type) {
        case SELECTTEST : 
            return {
                ...state,
                selecttestdata : actions.payload
               }
        case TESTQUESTIONS : 
        return {
            ...state,
            predefinedquestions : null,
            testquestions : actions.payload
            }
        case PREDEFINEDTESTQUESTIONS : 
        return {
            ...state,
            testquestions : null,
            predefinedquestions : actions.payload
            }
        case QUESTIONSNULL : 
        return {
            ...state,
            testquestions : null,
            predefinedquestions : null
            }
        default:
            return state
    }
}