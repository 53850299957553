import { SETCURRENTUSER, USERLOGOUT } from "../actions/type";
import isEmpty from "../utils/validate";

const intitalState = {
  isAuthenticated: false,
  user: null,
};

export default function (state = intitalState, actions) {
  switch (actions.type) {
    case SETCURRENTUSER:
      return {
        ...state,
        isAuthenticated: !isEmpty(actions.payload),
        user: actions.payload,
      };
    case USERLOGOUT:
      return {
        ...(state = undefined),
      };
    default:
      return state;
  }
}
